/*
 * @Autor: syq
 * @Date: 2021-07-15 16:48:17
 */
import request from "../../../request/request";

// 根据店铺名称查询店铺及其商品列表
const queryTreeNode = async (storeCode) =>
  request({
    url: `/realStore/realStore?qp-code-eq=${storeCode}`,
    method: "get",
  });
export { queryTreeNode };
